import axios from "axios";

export const getVersion = () => {
    let url = `${window.location.origin}/version.json`;
    return axios.get(url)
      .then((r) => {
        return `${r.data.year}.${r.data.month}.${r.data.date}.${r.data.versionCounter}`; // Return the data received from the response
      })
      .catch((e) => {
        console.error(e, "error"); // Log the error
        throw e; // Rethrow the error
      });
};  
export const getDotLiveProductionVersion = () => {
    let url = `${window.location.origin}/dotLiveVersionProduction.json`;
    return axios.get(url)
      .then((r) => {
        return `${r.data.year}.${r.data.month}.${r.data.date}.${r.data.versionCounter}`; // Return the data received from the response
      })
      .catch((e) => {
        console.error(e, "error"); // Log the error
        throw e; // Rethrow the error
      });
};  
export const getUsVersion = () => {
    let url = `${window.location.origin}/usProductionVersion.json`;
    return axios.get(url)
      .then((r) => {
        return `${r.data.year}.${r.data.month}.${r.data.date}.${r.data.versionCounter}`; // Return the data received from the response
      })
      .catch((e) => {
        console.error(e, "error"); // Log the error
        throw e; // Rethrow the error
      });
};  
export const getAEVersion = () => {
    let url = `${window.location.origin}/aeProductionVersion.json`;
    return axios.get(url)
      .then((r) => {
        return `${r.data.year}.${r.data.month}.${r.data.date}.${r.data.versionCounter}`; // Return the data received from the response
      })
      .catch((e) => {
        console.error(e, "error"); // Log the error
        throw e; // Rethrow the error
      });
};  

// export default getVersion;