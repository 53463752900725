import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import ChangePassword from "./views/Pages/Login/ChangePassword";
import {
  getAEVersion,
  getDotLiveProductionVersion,
  getUsVersion,
  getVersion,
} from "./AppVersion";
import {
  getDotLiveVersionStaging,
  getVersionStaging,
} from "./AppVersionStaging";
import { configuration } from "./appConfig";
import axios from "axios";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const LoginWithMfa = React.lazy(() =>
  import("./views/Pages/Login/LoginWithMfa")
);
const ForgotPassword = React.lazy(() =>
  import("./views/Pages/Login/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/Pages/Login/ResetPassword")
);
const CreatePassword = React.lazy(() =>
  import("./views/Pages/Login/CreatePassword")
);
const TwoFactorAuthentication = React.lazy(() =>
  import("./views/Pages/Login/TwoFactorAuthentication")
);
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="de-loader with-bg">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: "",
      appVersion: "",
      mobileappVersion: "",
    };
  }

  componentDidMount() {
    this.fetchVersion();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newToken =
      nextProps.props.login.loginDetails &&
      nextProps.props.login.loginDetails.access_token;
    if (String(newToken) !== String(prevState.authToken)) {
      return { authToken: newToken };
    } else {
      return null;
    }
  }
  fetchVersion = async () => {
    const versionStag = await getVersionStaging();
    const DotLiveVersionStag = await getDotLiveVersionStaging();
    const versionProd = await getVersion();
    const DotLiveVersionProd = await getDotLiveProductionVersion();
    const USVersionProd = await getUsVersion();
    const AEVersionProd = await getAEVersion();
    const currentOrigin = window.location.origin.replace(/\/$/, "");

    try {
      let Payload = "";

      // Determine the Payload based on currentOrigin
      if (currentOrigin == "http://localhost:3000") {
        Payload = { app_type: "web_app" };
      } else if (
        currentOrigin == "http://general-damage.driveandscan.com" ||
        currentOrigin == "http://www.general-damage.driveandscan.com"
      ) {
        Payload = { app_type: "live_app" };
      } else if (
        currentOrigin == "https://www.driveandscan.com" ||
        currentOrigin == "https://driveandscan.com"
      ) {
        Payload = { app_type: "web_app" };
      } else if (
        currentOrigin == "https://drivenscan.app" ||
        currentOrigin == "https://www.drivenscan.app"
      ) {
        Payload = { app_type: "web_app" };
      } else if (
        currentOrigin == "https://drivenscan.live" ||
        currentOrigin == "https://www.drivenscan.live"
      ) {
        Payload = { app_type: "live_app" };
      } else if (
        currentOrigin == "https://drivenscan.us" ||
        currentOrigin == "https://www.drivenscan.us"
      ) {
        Payload = { app_type: "us_app" };
      } else if (
        currentOrigin == "https://drivenscan.ae" ||
        currentOrigin == "https://www.drivenscan.ae"
      ) {
        Payload = { app_type: "ae_app" };
      } else {
        Payload = { app_type: "web_app" };
      }

      const URL = configuration.getVersion;
      try {
        const res = await axios.post(URL, Payload);

        if (res.data.success) {
          this.setState(
            {
              appVersion: res.data.data.web_app,
              mobileappVersion: res.data.data.mobile_app,
            },
            () => {
              const { appVersion } = this.state;

              // Check conditions and call AddVersionStaging/AddVersionProduction accordingly
              if (
                currentOrigin == "http://localhost:3000" &&
                appVersion != versionStag
              ) {
                this.AddVersionStaging("web_app");
              } else if (
                (currentOrigin == "http://general-damage.driveandscan.com" ||
                  currentOrigin ==
                    "http://www.general-damage.driveandscan.com") &&
                appVersion != DotLiveVersionStag
              ) {
                this.AddVersionStaging("live_app");
              } else if (
                (currentOrigin == "https://www.driveandscan.com" ||
                  currentOrigin == "https://driveandscan.com") &&
                appVersion != versionStag
              ) {
                this.AddVersionStaging("web_app");
              } else if (
                (currentOrigin == "https://drivenscan.app" ||
                  currentOrigin == "https://www.drivenscan.app") &&
                appVersion != versionProd
              ) {
                this.AddVersionProduction("web_app");
              } else if (
                (currentOrigin == "https://drivenscan.live" ||
                  currentOrigin == "https://www.drivenscan.live") &&
                appVersion != DotLiveVersionProd
              ) {
                this.AddVersionProduction("live_app");
              } else if (
                (currentOrigin == "https://drivenscan.us" ||
                  currentOrigin == "https://www.drivenscan.us") &&
                appVersion != USVersionProd
              ) {
                this.AddVersionProduction("us_app");
              } else if (
                (currentOrigin == "https://drivenscan.ae" ||
                  currentOrigin == "https://www.drivenscan.ae") &&
                appVersion != AEVersionProd
              ) {
                this.AddVersionProduction("ae_app");
              }
            }
          );
        }
      } catch (error) {
        console.error("Error fetching version:", error);
      }
    } catch (error) {
      console.error("Error setting payload:", error);
    }
  };

  AddVersionStaging = async (type) => {
    try {
      const { mobileappVersion } = this.state;
      const version = await getVersionStaging();
      const DotLiveVersionStag = await getDotLiveVersionStaging();
      const URL = configuration.addVersion;
      const Payload = {
        app_type: type,
        web_app: type == "web_app" ? version : DotLiveVersionStag,
        mobile_app: mobileappVersion,
      };

      const res = await axios.post(URL, Payload);
      if (res.data.success == true) {
        const { appVersion } = this.state;
        if (appVersion != version) {
          this.fetchVersion();
        }
      }
    } catch (error) {
      console.error("Error fetching version:", error);
    }
  };

  AddVersionProduction = async (type) => {
    try {
      const { mobileappVersion } = this.state;

      const version = await getVersion();
      const DotLiveVersionProd = await getDotLiveProductionVersion();
      const USVersionProd = await getUsVersion();
      const AEVersionProd = await getAEVersion();
      const currentOrigin = window.location.origin.replace(/\/$/, "");

      const versionType =
        type == "web_app"
          ? version
          : type == "live_app"
          ? DotLiveVersionProd
          : type == "us_app"
          ? USVersionProd
          : type == "ae_app"
          ? AEVersionProd
          : version;
      const URL = configuration.addVersion;
      const Payload = {
        app_type: type,
        web_app: versionType,
        mobile_app: mobileappVersion,
      };

      const res = await axios.post(URL, Payload);
      if (res.data.success == true) {
        const { appVersion } = this.state;
        if (
          (currentOrigin == "https://drivenscan.app" ||
            currentOrigin == "https://www.drivenscan.app") &&
          appVersion != version
        ) {
          this.AddVersionStaging();
        }
        if (
          (currentOrigin == "https://drivenscan.live" ||
            currentOrigin == "https://www.drivenscan.live") &&
          appVersion != DotLiveVersionProd
        ) {
          this.AddVersionStaging();
        }
        if (
          (currentOrigin == "https://drivenscan.us" ||
            currentOrigin == "https://www.drivenscan.us") &&
          appVersion != USVersionProd
        ) {
          this.AddVersionStaging();
        }
        if (
          (currentOrigin == "https://drivenscan.ae" ||
            currentOrigin == "https://www.drivenscan.ae") &&
          appVersion != AEVersionProd
        ) {
          this.AddVersionStaging();
        }
      }
    } catch (error) {
      console.error("Error fetching version:", error);
    }
  };
  render() {
    const { authToken, appVersion, mobileappVersion } = this.state;
    const isAuthenticated = Boolean(
      authToken &&
        authToken.trim() &&
        authToken !== "undefined" &&
        authToken !== undefined
    );

    return (
      <React.Suspense fallback={loading()}>
        <Switch>
          {/* Routes for unauthenticated users */}
          {!isAuthenticated && (
            <>
              <Switch>
                <Route
                  exact
                  path="/login"
                  render={(props) => (
                    <LoginWithMfa
                      {...props}
                      mobileAppVersion={mobileappVersion}
                      appVersion={appVersion}
                    />
                  )}
                />
                <Route
                  exact
                  path="/forgot-password"
                  render={(props) => (
                    <ForgotPassword
                      {...props}
                      mobileAppVersion={mobileappVersion}
                      appVersion={appVersion}
                    />
                  )}
                />
                <Route
                  exact
                  path="/reset-password/:id"
                  render={(props) => (
                    <ResetPassword
                      {...props}
                      mobileAppVersion={mobileappVersion}
                      appVersion={appVersion}
                    />
                  )}
                />
                <Route
                  exact
                  path="/create-password"
                  render={(props) => (
                    <CreatePassword
                      {...props}
                      mobileAppVersion={mobileappVersion}
                      appVersion={appVersion}
                    />
                  )}
                />
                <Route
                  exact
                  path="/two-factor-authentication"
                  render={(props) => (
                    <TwoFactorAuthentication
                      {...props}
                      mobileAppVersion={mobileappVersion}
                      appVersion={appVersion}
                    />
                  )}
                />
                <Route
                  exact
                  path="/register"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/changepassword"
                  render={(props) => <ChangePassword {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  render={(props) => <Page404 {...props} />}
                />
                <Redirect from="*" to="/login" />
              </Switch>
            </>
          )}

          {/* Routes for authenticated users */}
          {isAuthenticated && (
            <>
              <Switch>
                <PrivateRoute>
                  <Route
                    path="/*"
                    name="Dashboard"
                    render={(props) => (
                      <DefaultLayout
                        {...props}
                        mobileAppVersion={mobileappVersion}
                        appVersion={appVersion}
                      />
                    )}
                  />
                </PrivateRoute>
                <Route
                  exact
                  path="/404"
                  render={(props) => <Page404 {...props} />}
                />
              </Switch>
              {/* Redirect unauthenticated users */}
            </>
          )}
        </Switch>
      </React.Suspense>
    );
  }
}

const mapStateToProps = (props) => {
  return {
    props: props,
  };
};

export default connect(mapStateToProps)(withRouter(App));
