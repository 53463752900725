import axios from "axios";

export const getVersionStaging = () => {
    let url = `${window.location.origin}/versionStaging.json`;
    return axios.get(url)
      .then((r) => {
        return `${r.data.year}.${r.data.month}.${r.data.date}.V${r.data.versionCounter}`; // Return the data received from the response
      })
      .catch((e) => {
        console.error(e, "error"); // Log the error
        throw e; // Rethrow the error
      });
};  
export const getDotLiveVersionStaging = () => {
    let url = `${window.location.origin}/dotLiveVersionStaging.json`;
    return axios.get(url)
      .then((r) => {
        return `${r.data.year}.${r.data.month}.${r.data.date}.V${r.data.versionCounter}`; // Return the data received from the response
      })
      .catch((e) => {
        console.error(e, "error"); // Log the error
        throw e; // Rethrow the error
      });
};  
export const getUsVersionStaging = () => {
    let url = `${window.location.origin}/usMobileAppVersion.json`;
    return axios.get(url)
      .then((r) => {
        return `${r.data.year}.${r.data.month}.${r.data.date}.V${r.data.versionCounter}`; // Return the data received from the response
      })
      .catch((e) => {
        console.error(e, "error"); // Log the error
        throw e; // Rethrow the error
      });
};  

// export default getVersionStaging;